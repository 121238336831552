/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import Services from 'components/elements/Services'
import HeroAlt from 'components/elements/HeroAlt'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Third Party
import styled from 'styled-components'

const ServicesContainer = styled.div`
  margin-top: -250px;

  @media screen and (max-width: 991px) {
    margin-top: -150px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout activePage="/diensten/">
      <SEO yoast={yoast} path={path} />

      <HeroAlt fields={acf.banner} /> 

      <ServicesContainer className="container mb-lg-5 mb-3">
        <Services className="py-lg-5 py-3" />
      </ServicesContainer>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ServicesPageFrag
    }
  }
`

export default PageTemplate
